import "../../../styles/uniqueNews.css";
//import Slider from 'react-touch-drag-slider'
//import {Helmet} from "react-helmet";
//import  {  Helmet ,  HelmetProvider  }  from  'react-helmet-async' ;
import React from "react";
import api from "../../../services/api";
import CarouselIMG from "../img/CarouselIMG"
import Loading from "../others/Loading";
import {imagemURL} from "../../../services/variables"
import Footer from "../others/Footer";
export default class showNewsSytem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsLast: [],
      dataFormat: "",
      renderLoading: true,
      multiImg: []
    };
  }

              
  componentDidMount() {
    console.log(this.props.slugNewsSystem + " axios")
    api
      .post(`/news/unique/${this.props.slugNewsSystem}`)
      .then((res) => {
        const result = res.data;
        if (result.err) {
          //this.setState({renderLoading: false});
          //this.setState({renderLoading: false});

        } else {
          console.log(result);
          this.setState({ newsLast: result.res, renderLoading: false});
        }
      })
      .catch((err) => console.log("erro, DB") );
  }

  render() {
    return (
      <>
      {this.state.renderLoading ? <Loading/> : (<>
        {this.state.newsLast.map((news, i) => (
          <div className="card-unique-all" key={i}>
            <div className="section-unique-title">
              <h2>
                <img
                  className="icon-title"
                  alt="notícias"
                  src="/icons/seta-direita.svg"
                />
                {news.TITULO}
              </h2>
            </div>
            <div>

          
              <hr className="hr-news-unique"></hr>
            {news.IMG != 'logo.jpg' ? <></> : <><br/><br/><br/></>}

            </div>
            <div className="section-unique-news">
              <div className="cards-unique-news" key={i}>
                {news.IMG == 'logo.jpg' ? <></> : 
                <div>

                  <img
                    alt="imagem da notícia"
                    src={news.IMG == 'logo.jpg' ? '/imagens/logo.jpg' : imagemURL + news.IMG }
                    className="card-unique-img"
                  />
                </div>
                }
                <div className="card-unique-txt">
                  <div className="title-unique-body"></div>
                  <div
                    className="news-unique-body"
                    dangerouslySetInnerHTML={{ __html: news.CORPO_TEXTO }}
                  ></div>         
                </div>
                
              </div>
                <CarouselIMG img={news.MULTIPLE_IMG}/>
            </div>
            {news.IMG != 'logo.jpg' ? <></> : <><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></>}

          </div>

        ))}
        </>
        )}
      </>
    );
  }
}



